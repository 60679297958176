import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  hasPaymentRefund: boolean;
}

const initialState: AppState = {
  hasPaymentRefund: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppHasPaymentRefund: (state, action) => {
      state.hasPaymentRefund = action.payload;
    }
  }
});

export const { setAppHasPaymentRefund } = appSlice.actions;
