//FirebaseQp
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getStorage } from 'firebase/storage';
import 'firebase/compat/auth';
import Config from '../config';

const firebaseAppQP = firebase.initializeApp(
  Config.firebase_QP_Config,
  'quick-pass-prod' // TODO revisar.
);

const firestoreQP = firebaseAppQP.firestore();
const storageQP = getStorage(firebaseAppQP);

export { firebaseAppQP, storageQP, firestoreQP };
