import { createSlice } from '@reduxjs/toolkit';
import { Invitation, Ticket, OperationTicket } from 'src/models/ticket';

interface TicketState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  tickets: Ticket;
  activeTicket: Ticket;
  ticketsPaginated: Ticket[];
  page: number;
  limit: number;
  changeTicket: null;
  invitations: Invitation | any;
  allInvitations: Invitation;
  isLoadingInvitations: boolean;
  changeInvitations: null;
  isLoadingExcel: boolean;
  operationTicket: OperationTicket;
  ticketsByOperationId: Ticket[];
  reversedTickets: Ticket;
  deletedTicketsByOperationId: Ticket[];
}

export const emptyTicket = {
  items: [],
  size: 0
};

const emptyInvitations = {
  items: [],
  size: 0
};

const initialState: TicketState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  tickets: emptyTicket,
  activeTicket: null,
  ticketsPaginated: [],
  page: 0,
  limit: 25,
  changeTicket: null,
  invitations: emptyInvitations,
  allInvitations: emptyInvitations,
  isLoadingInvitations: false,
  changeInvitations: null,
  isLoadingExcel: false,
  operationTicket: null,
  ticketsByOperationId: [],
  reversedTickets: emptyTicket,
  deletedTicketsByOperationId: []
};

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    startLoadingTickets: (state) => {
      state.isLoading = true;
    },
    setTickets: (state, action) => {
      state.isLoading = false;
      state.tickets = action.payload;
    },
    setTicketsPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.ticketsPaginated = action.payload.tickets.data;
    },
    setChangeTicket: (state, action) => {
      state.changeTicket = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    setActiveTicket: (state, action) => {
      state.isLoading = false;
      state.activeTicket = action.payload;
    },
    setInvitations: (state, action) => {
      state.isLoadingInvitations = false;
      state.invitations = action.payload;
    },
    setAllInvitations: (state, action) => {
      state.isLoadingInvitations = false;
      state.allInvitations = action.payload;
    },
    stopLoadingTicket: (state) => {
      state.isLoading = false;
    },
    startLoadingInvitations: (state) => {
      state.isLoadingInvitations = true;
    },
    stopLoadingInvitations: (state) => {
      state.isLoadingInvitations = false;
    },
    setChangeInvitations: (state, action) => {
      state.changeInvitations = action.payload;
    },
    setIsLoadingExcel: (state) => {
      state.isLoadingExcel = true;
    },
    stopLoadingExcel: (state) => {
      state.isLoadingExcel = false;
    },
    resetTickets: (state) => {
      state.tickets = emptyTicket;
      state.ticketsPaginated = [];
      state.page = 0;
    },
    setOperationTicket: (state, action) => {
      state.operationTicket = action.payload;
    },
    setTicketsByOperationId: (state, action) => {
      state.ticketsByOperationId = action.payload;
    },
    setReversedTickets: (state, action) => {
      state.reversedTickets = action.payload;
    },
    setDeletedTicketsByOperationId: (state, action) => {
      state.deletedTicketsByOperationId = action.payload;
    }
  }
});

export const {
  startLoadingTickets,
  setTickets,
  setTicketsPaginated,
  setError,
  setChangeTicket,
  setActiveTicket,
  setInvitations,
  setAllInvitations,
  stopLoadingTicket,
  startLoadingInvitations,
  stopLoadingInvitations,
  setChangeInvitations,
  setIsLoadingExcel,
  stopLoadingExcel,
  resetTickets,
  setOperationTicket,
  setTicketsByOperationId,
  setReversedTickets,
  setDeletedTicketsByOperationId
} = ticketSlice.actions;
