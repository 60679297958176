import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { onSnapshot } from 'firebase/firestore';
import { firestoreQP } from 'src/utils/firebaseQP';
import AppInit from './components/AppInit';
import SnackbarUtilsConfigurator from './utils/sncakbarUtilsConfigurator';
import internationalization from 'src/i18n/i18n';
import { useDispatch } from 'src/store';
import PrivacyPolicie from './policies/privacy-policy/PrivacyPolicie';
import TycV2 from './policies/tyc-v2';
import { setAppHasPaymentRefund } from './slices/app/appSlice';

// TODO TEMPORALMENTE SE SETTEA ESPAÑOL POR DEFECTO VALIDAR CON QA DE PRUEBAS INTEGRALES
// MULTILENGUAJE.
// FILES INVOLUCRADOS: LOGIN (descomentar "LanguageSwitcher")
// SidebarFooter/index.tsx (descomentar "LanguageSwitcher")
internationalization.changeLanguage('es');

function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  const dispatch = useDispatch();

  // USE EFFECT PARA SABER SI EL TENANT TIENE VARIABLE DE ENTORNO "hasPaymentRefund"
  // Y QUE SE PERMITAN REVERSIONES
  useEffect(() => {
    const settingsDocRef = firestoreQP.collection('settings').doc('config');

    const unsubscribe = onSnapshot(settingsDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch(setAppHasPaymentRefund(data.hasPaymentRefund));
      } else {
        console.log('Config document does not exists');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <SnackbarUtilsConfigurator />
          <CssBaseline />
          {auth.isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
